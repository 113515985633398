import Navbar from "../component/navbar";
import Footer from "../component/Footer";
import Contact from "../component/Contact";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />

      <section className="py-16 bg-gray-100 ">
        <div className="absolute inset-0 bg-slate-950/80"></div>
        <div className="container mx-auto px-4 mt-[50px]">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.intro")}{" "}
              <a href="www.analabs.tech" className="font-bold">
                www.analabs.tech
              </a>
              .
            </p>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.contact")}
              <a href="mailto:contact@analabs.tech" className="text-slate-400">
                contact@analabs.tech
              </a>
              .
            </p>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section1.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section1.intro")}
            </p>

            <h3 className="text-2xl font-semibold text-orange-600 mb-4">
              {t("PrivacyPolicy.section1.a.title")}
            </h3>
            <ul className="list-disc list-inside text-gray-700 text-lg mb-6">
              <li>{t("PrivacyPolicy.section1.a.content")}</li>
            </ul>

            <h3 className="text-2xl font-semibold text-orange-600 mb-4">
              {t("PrivacyPolicy.section1.b.title")}
            </h3>
            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section1.b.content")}
            </p>
            <ul className="list-disc list-inside text-gray-700 text-lg mb-6">
              <li>{t("PrivacyPolicy.section1.b.logData")}</li>
              <li>{t("PrivacyPolicy.section1.b.cookies")}</li>
            </ul>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section2.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section2.content")}
            </p>
            <ol className="list-decimal list-inside text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section2.list", { returnObjects: true }).map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ol>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section3.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section3.content")}
            </p>
            <ul className="list-disc list-inside text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section3.list", { returnObjects: true }).map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section4.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section4.content")}
            </p>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section5.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section5.intro")}
            </p>
            <ul className="list-disc list-inside text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section5.list", { returnObjects: true }).map(
                (item, index) => (
                  <li key={index}>{item}</li>
                )
              )}
            </ul>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section5.contact")}{" "}
              <a href="mailto:contact@analabs.tech" className="text-slate-400">
                contact@analabs.tech
              </a>
              .
            </p>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section6.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section6.content")}
            </p>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section7.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section7.content")}
            </p>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section8.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section8.content")}
            </p>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section9.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section9.content")}
            </p>

            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("PrivacyPolicy.section10.title")}
            </h2>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section10.intro")}
            </p>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section10.email")}{" "}
              <a href="mailto:contact@analabs.tech" className="font-bold">
                contact@analabs.tech
              </a>
            </p>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section10.website")}{" "}
              <a href="www.analabs.tech" className="font-bold">
                www.analabs.tech
              </a>
            </p>

            <p className="text-gray-700 text-lg mb-6">
              {t("PrivacyPolicy.section10.thankYou")}
            </p>
          </div>
        </div>
      </section>

      <Contact />

      <Footer />
      {/* <Switcher /> */}
    </>
  );
};
