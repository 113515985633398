import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Products() {
  const { t } = useTranslation();
  return (
    <>
      <section className="relative md:py-24 py-16" id="products">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h5 className="text-orange-600 text-xl font-medium uppercase mb-2">
              {t("Products.title")}
            </h5>
          </div>

          <div className=" grid grid-cols-1 md:grid-cols-2 gap-3">
            <Link to={"/product-insight"}>
              <div
                className={`bg-orange-600 w-full p-6 rounded-lg shadow-lg min-h-[216px]`}
              >
                <h4 className="text-white text-2xl font-semibold mb-2">
                  {t("Products.list.insight.title")}
                </h4>
                <p className="text-gray-200 text-base">
                  {t("Products.list.insight.description")}
                </p>
                <button className="mt-4 bg-white text-orange-600 font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-300 ease-in-out">
                  {t("Products.moreInfo")}
                </button>
              </div>
            </Link>

            <Link to={"/product-sales"}>
              <div
                className={`bg-[#3b2aa6] w-full p-6 rounded-lg shadow-lg min-h-[216px]`}
              >
                <h4 className="text-white text-2xl font-semibold mb-2">
                  {t("Products.list.sales.title")}
                </h4>
                <p className="text-gray-200 text-base">
                  {t("Products.list.sales.description")}
                </p>
                <button className="mt-4 bg-white text-[#3b2aa6] font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-300 ease-in-out">
                  {t("Products.moreInfo")}
                </button>
              </div>
            </Link>

            <Link to={"/product-retail"}>
              <div
                className={`bg-[#6f42c1] w-full p-6 rounded-lg shadow-lg min-h-[216px]`}
              >
                <h4 className="text-white text-2xl font-semibold mb-2">
                  {t("Products.list.retail.title")}
                </h4>
                <p className="text-gray-200 text-base">
                  {t("Products.list.retail.description")}
                </p>
                <button className="mt-4 bg-white text-[#6f42c1] font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-300 ease-in-out">
                  {t("Products.moreInfo")}
                </button>
              </div>
            </Link>

            <Link to={"/product-filesmart"}>
              <div
                className={`bg-[#20c997] w-full p-6 rounded-lg shadow-lg min-h-[216px]`}
              >
                <h4 className="text-white text-2xl font-semibold mb-2">
                  {t("Products.list.file.title")}
                </h4>
                <p className="text-gray-200 text-base">
                  {t("Products.list.file.description")}
                </p>
                <button className="mt-4 bg-white text-[#20c997] font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-300 ease-in-out">
                  {t("Products.moreInfo")}
                </button>
              </div>
            </Link>
          </div>

          <Link to={"/product-360"}>
            <div
              className={`bg-gradient-to-r from-orange-600 via-[#3b2aa6] to-[#20c997] w-full p-6 rounded-lg shadow-lg  mt-3`}
            >
              <h4 className="text-white text-2xl font-semibold mb- text-center">
                {t("Products.list.360.title")}
              </h4>
              <p className="text-black text-base text-center rounded bg-white py-2 px-4 max-w-max mx-auto">
                <strong className="text-orange-600">
                  {t("Products.list.insight.title")}
                </strong>{" "}
                +{" "}
                <strong className="text-[#3b2aa6]">
                  {t("Products.list.sales.title")}
                </strong>{" "}
                +{" "}
                <strong className="text-[#20c997]">
                  {t("Products.list.file.title")}
                </strong>
                .
              </p>
              <button className="mt-4 w-full bg-white text-orange-600 font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-300 ease-in-out">
                {t("Products.moreInfo")}
              </button>
            </div>
          </Link>
        </div>
      </section>
    </>
  );
}
