import blog1 from "../assets/images/blog/1.jpg";
import blog2 from "../assets/images/blog/2.jpg";
import blog3 from "../assets/images/blog/3.jpg";

export const blogData = [
  {
    id: 1,
    title: "Building Your Corporate Identity from Upwind",
    description:
      "The phrasal sequence of the is now so that many campaign and benefit",
    image: blog1,
  },
  {
    id: 2,
    title: "The Right Hand of Business IT World",
    description:
      "The phrasal sequence of the is now so that many campaign and benefit.",
    image: blog2,
  },
  {
    id: 3,
    title: "Building Your Corporate Identity from Upwind",
    description:
      "The phrasal sequence of the is now so that many campaign and benefit",
    image: blog3,
  },
];
