import Navbar from "../component/navbar";
import Footer from "../component/Footer";
import Switcher from "../component/Switcher";
import Contact from "../component/Contact";
import { useTranslation } from "react-i18next";

export const Product360 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />

      <section className="py-16 bg-gray-100 ">
        <div className="absolute inset-0 bg-slate-950/80"></div>
        <div className="container mx-auto px-4 mt-[50px]">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold text-orange-600 mb-4">
              {t("Products.show.360.title")}
            </h2>
            <p className="text-gray-700 text-lg mb-6">
              <strong className="text-orange-600">
                {" "}
                {t("Products.show.360.title")}
              </strong>{" "}
              {t("Products.show.360.description")}
            </p>
            <h3 className="text-2xl font-semibold text-orange-600 mb-4">
              {t("Products.show.titleDescription")}
            </h3>
            <ul className="list-disc list-inside text-gray-700 text-lg mb-6">
              <li>
                <strong className="text-[#3b2aa6]">
                  {t("Products.show.360.benefit1")}
                </strong>{" "}
              </li>
              <li>
                <strong className="text-[#6f42c1]">
                  {t("Products.show.360.benefit2")}
                </strong>
              </li>
              <li>
                <strong className="text-[#20c997]">
                  {t("Products.show.360.benefit3")}
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Contact />

      <Footer />
      {/* <Switcher /> */}
    </>
  );
};
